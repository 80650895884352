import styled from 'styled-components';

const VendorPartnerLogosStyles = styled.div`
    h1 {
        font-size: 18px;
        font-weight: 800;
        line-height: 25px;
        padding-bottom: 30px;
        padding-top: 30px;
        text-align: center;
    }

    .Logo__container {
        padding: 16px;
    }

    .slick-list {
        max-height: 200px;
    }

    .slick-track {
        max-height: 200px;
    }
`;

export default VendorPartnerLogosStyles;
