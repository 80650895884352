import styled from 'styled-components';

const Title = styled.h1`
  color: ${props => props.theme.orange};
  display: flex;
  font-size: 35px;
  font-weight: 800;
  justify-content: center;
  line-height: 48px;
  padding: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 41px;
  }
`;

export default Title;
